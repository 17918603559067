/* AppleSignInButton.module.css */

.appleSignInButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: white;
    color: rgb(50,50,50);
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    padding: 10px 20px;
    width: 70%;
    margin: 0px auto;
}


.appleSignInButton:hover {
    cursor: pointer;
}

span.icon img {
    width: 36px;
    height: 20px;
  }

  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
  }