.RaeJewelryDesignerTool-menu {
    width: 20%;
    height: 100vh;
    background:rgb(250,225,215);
    box-shadow: 10px 0 10px -5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px !important;
    left: 0px;
    z-index: 400;
}

.menuItem.menuInActive {
    position: absolute;
    left: 50px;
    top: 50px;
    font-size: 20px;
    cursor: pointer;
}

.RaeJewelryDesignerTool-menu-header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    margin-top: 30px;
    margin-bottom:40px;
  }

  .MenuCloseButton {
    color: rgb(50,50,50);
    background: rgba(50,50,50,0);
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    z-index: 4;
    font-size: 24px;
    position: static;
    order: 2;
    margin-right: 10px;
    padding-bottom: 5px;
  }

.BackToRaeButton {
    border: solid 1px rgb(50,50,50);
    background: rgba(50,50,50,1);
    color: white;
    padding: 10px;
    width: 80%;
    margin: 10px 10%;
}

.LogoutButton {
    border: solid 1px rgba(237,190,174,1);
    background: rgba(237,190,174,1);
    color: rgba(50,50,50,1);
    padding: 10px;
    width: 80%;
    margin: 10px 10%;
}

.RaeJewelryDesignerTool-menu-header img.logo {
    width: 30%;
    margin: 40px auto;
    margin-left: 0px;
    position: relative;
    top: 5px;
    left: 10px;
}

.RaeJewelryDesignerTool-menu-items , .RaeJewelryDesignerTool-menu-settings, .RaeJewelryDesignerTool-menu-logout {
    width: 100%;
    margin: 0px auto;
    position: sticky;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.RaeJewelryDesignerTool-menu-settings {
    margin-top: 0%;
}

.RaeJewelryDesignerTool-menu-logout {
    position: absolute;
    bottom: 30px;
}

.RaeJewelryDesignerTool-menu-items a, .RaeJewelryDesignerTool-menu-settings a, .RaeJewelryDesignerTool-menu-logout a {
    all:unset;
    cursor: pointer;
}

.RaeJewelryDesignerTool-menu-items div, .RaeJewelryDesignerTool-menu-settings div, .RaeJewelryDesignerTool-menu-logout div {
    width: 100%;
    height: 40px;
    font-size: 16px;
    margin: 2px 0px;
    padding-left: 10%;
    display: flex;
    align-items: center;
    color: rgba(50,50,50, 1);
    box-sizing: border-box;
}

.RaeJewelryDesignerTool-menu-items div.active, .RaeJewelryDesignerTool-menu-settings div.active, .RaeJewelryDesignerTool-menu-logout div.active {
    color: rgb(50,50,50);
    background: rgba(237,190,174,0.5);
}

.RaeJewelryDesignerTool-menu-items div:hover, .RaeJewelryDesignerTool-menu-settings div:hover, .RaeJewelryDesignerTool-menu-logout div:hover {
    color: rgb(50,50,50);
    background: rgba(237,190,174,0.5);
}

div.RaeJewelryDesignerTool-menu-items-titleHolder:hover {
    color: rgb(50,50,50);
}

.RaeJewelryDesignerTool-menu-items-item-icon {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RaeJewelryDesignerTool-menu-items-item-text {
    width: 90%;
    padding-left:10px;
}
  
  @media (max-width: 800px) {
  
    .RaeJewelryDesignerTool-menu {
        width: 100%;
    }

    .RaeJewelryDesignerTool-menu-header img.logo {
        width: 40%;
    }

    .RaeJewelryDesignerTool-menu-logout {
        position: absolute;
        bottom: 10vh;
    }
      
  }

  @media (max-height: 700px) {
  

    .RaeJewelryDesignerTool-menu-logout {
        position: absolute;
        bottom: 6vh;
    }

    .RaeJewelryDesignerTool-menu-items , .RaeJewelryDesignerTool-menu-settings, .RaeJewelryDesignerTool-menu-logout {
        width: 100%;
        margin: 0px auto;
        position: sticky;
        display: flex;
        flex-direction: column;
        gap:3px;
        font-size: 14px;
    }
      
  }