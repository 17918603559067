 /* Landing page desktop */
 .landingPage{
    width: 100%;
    height: 110vh;
    background:rgb(250,225,216);
    position: relative;
    top: -20px;
 }

 .landingPage-titleRae h1 {
    position: absolute;
    top: 15%;
    left: 12%;
    font-size: 150px;
    font-weight: 700;
    z-index: 2;
    color:rgba(0,0,0,0.8);
 }

 .landingPage-titleTagline h2 {
    position: absolute;
    top: 33%;
    left: 20%;
    font-size: 70px;
    font-weight: 400;
    letter-spacing: 4px;
    z-index: 2;
    color:rgba(0,0,0,0.8);
 }

 .landingPage-introText p {
    position: absolute;
    top: 47%;
    left: 12%;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    z-index: 2;
 }

 .landingPage-introText-mobile {
   display: none;
 }

 .landingPage-introCallToActionCollection {
    position: absolute;
    top: 58%;
    left: 12%;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    padding: 10px 0px;
    cursor: pointer;
 }



 .landingPage-introCallToActionCollection:hover {
    font-weight: 600;
 }

 .landingPage-callToAction-circleButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px black solid;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
 }

 .landingPage-callToAction-circleButton .arrowIcon {
    color: black;
    border: none;
    font-weight: 300;
 }

 .landingPage-introCallToActionCollection button {
    all:unset;
    margin-left: 10px;
 }

 .landingPage-introCallToActionCollection button:hover {
    font-weight: 800;
 }

 .landingPage-introCallToActionOneOfAKind {
    position: absolute;
    bottom: 12%;
    right: 8%;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    cursor: pointer;
 }

 .landingPage-introCallToActionOneOfAKind:hover {
    font-weight: 600;
 }

 .landingPage-introCallToActionOneOfAKind button {
    all:unset;
    margin-bottom: 10px;
 }

 .landingPage-heroImage img {
    position: absolute;
    height: 80%;
    bottom: 0px;
    right: 20%;
 }

 .landingPage-sideImage img {
    position: absolute;
    height: 20%;
    bottom: 30%;
    right: 10%;
 }

 @media (max-width: 1600px) {
    .landingPage-titleRae h1 {
        font-size: 120px;
        left: 10%;
    }
    .landingPage-titleTagline h2 {
        font-size: 60px;
        left: 18%;
    }

    .landingPage-introText p {
        font-size: 18px;
        left: 10%;
    }

    .landingPage-introCallToActionCollection, .landingPage-introCallToActionOneOfAKind {
        font-size: 18px;
    }

    .landingPage-introCallToActionCollection {
        left: 10%;
    }
  
 }

 /* the md breakpoint */
@media (max-width: 900px) {
   .landingPage-titleRae h1 {
      position: absolute;
      top: 14%;
      left: 12%;
      font-size: 50px;
   }
   .landingPage-titleTagline h2 {
      position: absolute;
    top: 24%;
    left: 18%;
    font-size: 20px;
   }
   .landingPage-introText p {
      top: 30%;
      left: 12%;
      font-size: 12px;
   }

   .landingPage-introText-mobile {
      display: inline;
    }

   .landingPage-introText-desktop {
      display: none;
    }

   .landingPage-heroImage {
      position: absolute;
         height: 56%;
         width: 53%;
         bottom: 0px;
         right: auto;
         left: 12%;
         overflow: hidden;
    }

    .landingPage-heroImage img {
      position: relative;
      height: 100%;
   }
  
   .landingPage-sideImage img {
      position: absolute;
      height: 14%;
      bottom: 25%;
      right: 12%;
   }

   .landingPage-introCallToActionCollection {
      top: 36.5%;
      left: 12%;
      font-size: 12px;
   }
  
   .landingPage-callToAction-circleButton {
      width: 20px;
      height: 20px;
   }
  
  
   .landingPage-introCallToActionOneOfAKind {
      font-size: 12px;
      right: 6%;
   }
}

/* the md breakpoint */
@media (max-width: 950px) and (min-height:750px) {

   .landingPage-titleRae h1 {
      position: absolute;
      top: 16%;
      left: 12%;
      font-size: 50px;
   }

   .landingPage-titleTagline h2 {
    font-size: 24px;
    top: 23%;
   }

   .landingPage-introText p {
      top: 30%;
      left: 12%;
      font-size: 13px;
   }

   .landingPage-introCallToActionCollection {
      font-size: 13px;
   }

   .landingPage-introCallToActionOneOfAKind {
      font-size: 12px;
      right: 5%;
      bottom: 12%;
   }

   .landingPage-heroImage {
      position: absolute;
         height: 56%;
         width: 52%;
         bottom: 0px;
         right: auto;
         left: 12%;
         overflow: hidden;
    }

    .landingPage-heroImage img {
      height: 100%;
      margin-left: -10px;
    }

   .landingPage-sideImage img {
      position: absolute;
      height: 14%;
      bottom: 23%;
      right: 10%;
   }
   
}
/* Dimensions surface pro 7 */

@media (max-width: 950px) and (min-height:1100px) {

   .landingPage-titleRae h1 {
      position: absolute;
      top: 14%;
      left: 12%;
      font-size: 90px;
   }

   .landingPage-titleTagline h2 {
      font-size: 45px;
      top: 23%;
     }
  
     .landingPage-introText p {
        top: 30%;
        left: 12%;
        font-size: 26px;
     }
  
     .landingPage-introCallToActionCollection {
        font-size: 26px;
        top: 38%;
        left: 12%;
     }
  
     .landingPage-introCallToActionOneOfAKind {
        font-size: 26px;
        right: 8%;
        bottom: 11%;
     }
  
     .landingPage-heroImage {
        position: absolute;
           height: 55%;
           width: 70%;
           bottom: 0px;
           right: auto;
           left: 12%;
           overflow: hidden;
      }
  
      .landingPage-heroImage img {
        height: 100%;
        margin-left: -10px;
      }
  
     .landingPage-sideImage img {
        position: absolute;
        height: 14%;
        bottom: 23%;
        right: 10%;
     }
}

/* Dimensions nest hub */

@media (min-width: 800px) and (max-height: 650px) {
   .landingPage-titleRae h1 {
      position: absolute;
      top: 16%;
      left: 10%;
      font-size: 90px;
   }
   .landingPage-titleTagline h2 {
      font-size: 45px;
      top: 34%;
     }

     .landingPage-introText p {
      top: 47%;
      left: 10%;
      font-size: 16px;
   }

   .landingPage-introCallToActionCollection {
      font-size: 16px;
      top: 59%;
   }

   .landingPage-introCallToActionOneOfAKind {
      font-size: 16px;
      right: 6%;
      bottom: 10%;
   }

   .landingPage-callToAction-circleButton {
      width: 30px;
      height: 30px;
   }
}

/* Dimensions ipad Air */

@media (min-width: 800px) and (max-width:1000px) and (max-height: 1200px) {
   .landingPage-titleRae h1 {
      position: absolute;
      top: 11%;
      left: 12%;
      font-size: 100px;
   }
   .landingPage-titleTagline h2 {
      font-size: 50px;
      top: 22%;
     }

     .landingPage-introText p {
      top: 31%;
      left: 12%;
      font-size: 20px;
   }

   .landingPage-introCallToActionCollection {
      font-size: 20px;
      top: 38%;
   }

   .landingPage-introCallToActionOneOfAKind {
      font-size: 20px;
      right: 9%;
      bottom: 10%;
   }

   .landingPage-callToAction-circleButton {
      width: 40px;
      height: 40px;
   }
   .landingPage-heroImage {
      position: absolute;
         height: 55%;
         width: 50%;
         bottom: 0px;
         right: auto;
         left: 12%;
         overflow: hidden;
    }

    .landingPage-heroImage img {
      height: 100%;
    }

    .landingPage-sideImage img {
      position: absolute;
      height: 16%;
      bottom: 23%;
      right: 14%;
   }
}

/* Dimensions ipadMini */

@media (min-width: 700px) and (max-width:850px) and (max-height: 1100px) {
   .landingPage-titleRae h1 {
      position: absolute;
      top: 8.5%;
      left: 12%;
      font-size: 100px;
   }
   .landingPage-titleTagline h2 {
      font-size: 50px;
      top: 20%;
     }

     .landingPage-introText p {
      top: 29%;
      left: 12%;
      font-size: 20px;
   }

   .landingPage-introCallToActionCollection {
      font-size: 20px;
      top: 37%;
   }

   .landingPage-introCallToActionOneOfAKind {
      font-size: 20px;
      right: 9%;
      bottom: 10%;
   }

   .landingPage-callToAction-circleButton {
      width: 40px;
      height: 40px;
   }
   .landingPage-heroImage {
      position: absolute;
         height: 55%;
         width: 50%;
         bottom: 0px;
         right: auto;
         left: 12%;
         overflow: hidden;
    }

    .landingPage-heroImage img {
      height: 100%;
    }

    .landingPage-sideImage img {
      position: absolute;
      height: 16%;
      bottom: 23%;
      right: 14%;
   }
}