/* Mobile ---------------------------------------------------------------------------*/

.DesignerLoginPage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background:rgb(237,190,174);
  color: white;
  font-family: 'Roboto', sans-serif;
}

.DesignerLoginPage .FlyingCheezItInBackground {
  position: absolute;
  width: 200px;
  z-index: 0;
}

.DesignerLoginPage-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/LoginBackground.png");
  background-color: rgb(237,190,174); 
}


.DesignerLoginPage-loginCard {
  background-color: rgba(250,225,215,0.5);
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  box-shadow: none;
  padding-bottom: 50px;
}

.DesignerLoginPage-logo {
  width: 50%;
  margin: 10px auto;
  position: relative;
  top: 5vh;
}

.DesignerLoginPage-loginCard-backgroundTop {
  position: absolute;
  display: block;
  width: 100%;
}

.DesignerLoginPage-loginCard-sso-logins {
  width: 80%;
  margin: 40px auto;
  width: 90%;
  text-align: left;
  margin-left: 10%;
}

.DesignerLoginPage-loginCard-sso-logins h1 {
  margin-bottom: 10px;
  font-size: 20px;
}

.DesignerLoginPage-loginCard-sso-logins p {
  margin-top: 10px;
  font-size: 14px;
}

.DesignerLoginPage-loginCard-header div.smallDevider {
  height: 3px;
  width: 25%;
  background: white;
  margin-top: 20px;
}

.DesignerLoginPage-loginCard-body {
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.DesignerLoginPage-loginCard-body form{
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
}

.DesignerLoginPage-loginCard-body label {
  text-align: left;
  margin-bottom: 7px;
  margin-top: 16px;
  font-size: 14px;
  color: rgb(50,50,50);
}

.DesignerLoginPage-loginCard-body input {
  padding: 10px 0px;
  margin-bottom: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 15px;
  background-color: #292933;
  background-color: white;
  color: white;
  color: black;
  font-size: 14px;
}

.DesignerLoginPage-loginCard-body input:focus{
  background-color: #292933;
  background-color: white;
  padding-left: 15px;
  outline: none;
}

.DesignerLoginPage-loginCard-body button.submitButton {
  margin: 10px auto;
  background-color: #292933;
  color: white;
  border-radius: 5px;
  border: transparent solid 1px;
  width: 100%;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px 0px;
}

.forgotPassword {
  text-align: right;
  margin-top: -5px;
  font-style: 10px;
  font-style: italic;
  cursor: pointer;
  color:rgb(50,50,50);
  font-weight: bold;
  font-size: 12px;
}

.DesignerLoginPage-loginCard-footer{
  width: 100%;
  font-size: 15px;
  color: rgb(50,50,50);
}

.DesignerLoginPage-loginCard-footer a {
  color: rgb(50,50,50);
  font-weight: bold;
}

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  width: 70%;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}

.line {
  flex: 1;  /* makes the lines take up equal space on both sides */
  border-bottom: 1px dashed rgb(50,50,50); /* creates a dotted line */
  margin: 0 10px; /* adds some spacing around the text */
}

.text {
  white-space: nowrap; /* ensures the text does not wrap */
  padding: 0 10px; /* optional: adds some spacing around the text */
  font-size: 18px;
  color: rgb(50,50,50);
}



/* Tablet ---------------------------------------------------------------------------*/
@media all and (min-width: 900px) {
  .DesignerLoginPage-loginCard {
    width: 55%;
    height: 90%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
  }
}
/* Laptop ---------------------------------------------------------------------% */

@media all and (min-width: 1100px) {

  .DesignerLoginPage-loginCard {
    width: 35%;
    height: auto;
    margin: 40px auto;
  }

  .DesignerLoginPage {
    height: auto;
    min-height: 100vh;
  }

}