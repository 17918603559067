/* Mobile ---------------------------------------------------------------------------*/

.RaeRegisterPage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(237,190,174); 
  background-image: url("../assets/LoginBackground.png");
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image in the element */
  background-repeat: no-repeat;
  color: rgb(50,50,50);
  font-family: 'Roboto', sans-serif;
  }
  
  
  .RaeRegisterPage-registerCard {
    
    background:rgb(237,190,174);
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: none;
    padding-bottom: 30px;
  }

  .RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified {
    background:rgb(237,190,174);
    text-align: center;
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 0px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified p {
    padding-left: 20px;
    padding-right: 20px;
  }

.RaeRegisterPage-registerCard-backgroundTop {
    position: absolute;
    display: block;
    width: 100%;
  }
  
  .RaeRegisterPage-registerCard-header {
    width: 80%;
    margin: 40px auto;
    position: relative;
    width: 90%;
    text-align: left;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .RaeRegisterPage-logo {
    width: 60%;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  
  .RaeRegisterPage-registerCard-header h1 {
    margin-bottom: 10px;
    font-size: 28px;
  }
  
  .RaeRegisterPage-registerCard-header p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .RaeRegisterPage-registerCard-header div.smallDevider {
    height: 3px;
    width: 25%;
    background: white;
    margin-top: 10px;
  }
  
  .RaeRegisterPage-registerCard-body {
    width: 90%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  
  .RaeRegisterPage-registerCard-body form{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 90%;
  }

  .RaeRegisterPage-registerCard-body-inputArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .RaeRegisterPage-registerCard-body label {
    text-align: left;
    margin-bottom: 7px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .RaeRegisterPage-registerCard-body input {
    padding: 10px 0px;
    margin-bottom: 15px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-left: 5px;
    background-color: rgba(255,255,255,0.8);
    color:rgb(50,50,50);
    font-size: 14px;
    padding-right: 70px;
    padding-left: 10px;
  }
  
  .RaeRegisterPage-registerCard-body input:focus{
    background-color: #292933;
    background-color: white;
    padding-left: 5px;
    outline: none;
    padding-left: 10px;
  }

  .RaeRegisterPage-registerCard-body-inputArea-visibilityIcon {
    position: absolute;
    right: 20px;
    top: 55%;
    cursor: pointer;
    padding: 5px;
    color: rgba(200,200,200);
    color: rgba(50,50,50);
  }
  
  .RaeRegisterPage-registerCard-body button.submitButton {
    margin: 10px auto;
    background-color: #292933;
    color: white;
    border-radius: 5px;
    border: transparent solid 1px;
    width: 100%;
    margin-top: 50px;
    font-size: 16px;
    text-justify: center;
    padding:10px;
  }

  .RaeRegisterPage-registerCard-body button.submitButton:hover {
    font-weight: 600;
  }
  
  .RaeRegisterPage-registerCard-footer{
    width: 100%;
    font-size: 12px;
  }
  
  .RaeRegisterPage-registerCard-footer a {
    color: white;
    font-weight: bold;
  }
  
  
  
  /* Tablet ---------------------------------------------------------------------------*/
  @media all and (min-width: 800px) {
    .RaeRegisterPage-registerCard {
      width: 55%;
      height: auto;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-radius: 5px;
    }
    .RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified {
      width: 55%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      border-radius: 5px;
    }
  }
  /* Laptop ---------------------------------------------------------------------% */
  
  @media all and (min-width: 1100px) {
  
    .RaeRegisterPage-registerCard {
      width: 35%;
      margin: 40px auto;
    }

    .RaeRegisterPage-registerCard-registeredButEmailNotYerVerrified {
      width: 35%;
    }

    .RaeRegisterPage {
      height: auto;
      min-height: 100vh;
    }
  
  }