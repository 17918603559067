/* Laptop --------------------------------------------------------------*/

.NavbarWebTop{
    position: absolute;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10vh;
    z-index: 1000;
}

.NavbarWebTop-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 15%;
    color: #000;
    font-size: 30px;
    font-weight: 800;
}

.NavbarWebTop-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 35%;
}

.NavbarWebTop-links a {
    position: relative;
    top: 2px;
    color: #000;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 15px;
    margin: 0 1rem;
    cursor: pointer;
    
}

.NavbarWebTop-cart {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;
    width: 50%;
}

.NavbarWebTop-cart-icon {
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 15px;
    margin: 0 1rem;
    cursor: pointer;
    margin-right: 10%;
    position: relative;
}

.NavbarWebTop-cart a.navbar-cart-register {
    margin-right: 10%;
    border: 1px solid #fff;
}

.NavbarWebTop-cart a.navbar-cart-register:hover {
    background-color: #fff;
    color: #000;
}

.NavbarWebTop-menuToggle {
    display: none;
}

.numberOfCartItems {
    position: absolute;
    top: 32%;
    right: 44%;
    font-size: 1rem;
    border-radius: 50%;
}

/* Mobile ---------------------------------------------------------------------------*/
@media all and (max-width: 1350px) {
    .NavbarWebTop {
        position: fixed;
        flex-wrap: wrap;
        background-color: black;
        height: 100%;
        align-content: space-between;
    }

    .NavbarWebTop.inactive {
        position: absolute;
    }

    .NavbarWebTop-logo {
        width: 40%;
        height: 10vh;
        justify-content: left;
        margin-left: 10%;
        font-size: 1.7rem;
    }
    .NavbarWebTop-menuToggle {
        display:flex;
        justify-content: right;
        align-items: center;
        height: 10vh;
        width: 40%;
        margin-right:10%;
    }
    .NavbarWebTop-menuToggle .toggleBars {
        font-size: 1.5rem;
        cursor: pointer;
    }

    .NavbarWebTop-links {
        position: fixed;
        width: 100%;
        height: auto;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 30px;
    }
    .NavbarWebTop-links a {
        font-size: 1.3rem;
        width: 100%;
        color:white;
        margin-bottom: 20px;
    }
    .NavbarWebTop-cart {
        width: 100%;
        height: auto;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .NavbarWebTop-cart a {
        font-size: 1.5rem;
        width: 55%;
        color:white;
    }
    .NavbarWebTop-cart a.navbar-cart-register {
        margin-right: 1rem;
        margin-top: 5px;
        border: 1px solid #fff;
    }

    .NavbarWebTop-cart-icon {
        margin-right: 0px;
    }

    .numberOfCartItems {
        position: absolute;
        top: 32%;
        right: 48.4%;
    }

    /* Mobile Menu inactive --------------------------*/

    .NavbarWebTop.inactive {
        background-color: transparent;
        height: 10vh;
    }

    .NavbarWebTop.inactive .navbar-logo {
        color: #fff;
    }

    .NavbarWebTop-links.inactive {
        display: none;
    }

    .NavbarWebTop-cart.inactive {
        display: none;
    }
    .white {
        color: #fff;
    }
}