.cartButton {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  margin-right: 5vw;
  font-size: 20px;
}

.sideModal.cart {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 30%;
    background-color: white;
    box-shadow: -5px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 1000;
  }

  .sideModal button.cartCloseButton {
    align-self: flex-end;
    border: none;
    background: none;
    font-size: 20px;
  }

  .sideModal-cart-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    width: 100%;
    height: 100%;
  }
  
  .sideModal-cart-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
    background: rgb(240,240,240);
    width: 100%;
  }

  .sideModal-cart-item-imageContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.sideModal-cart-item-image {
    width: 90%;
  }

  .sideModal-cart-item-specContainer {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
  }

  .sideModal-cart-item-subTotalPriceContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sideModal-cart-item-deleteButtonContainer {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  

  .sideModal-cart-bottomCheckout-totalPrice {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 15px;
    font-size: 16px;
  }

  .sideModal-cart-bottomCheckout-checkoutButton {
    width: 100%;
    height: 50px;
    background-color: black;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin: 40px 0;
    margin-top: 20px;
  }

  .sideModal-cart-bottomCheckout-checkoutButton:disabled {
    width: 100%;
    height: 50px;
    background-color: black;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    margin: 40px 0;
    margin-top: 20px;
    opacity: 0.7;
  }

  .sideModal-cart-bottomCheckout-checkoutButton:hover, .sideModal-cart-bottomCheckout-checkoutButton:focus, .sideModal-cart-bottomCheckout-checkoutButton:active {
    font-weight: bold;
    background-color: black;
    box-shadow: none;
  }

  @media (max-width: 900px) {

  .cartButton {
    color: white;
  }

  .sideModal.cart {
    width: 100%;
    height: 100%;
  }
}