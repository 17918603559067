.dropdown-container{
    padding: 10px;
    width: 100%;
    padding-right:20px;
    margin-bottom: 30px;
}

.dropdown-container select {
    padding: 10px;
    width: 100%;
}

.dropdown-container.mobileOnly {
    display: none;
}

@media (max-width: 900px) {
    .dropdown-container.mobileOnly {
        display: flex;
    }
}