.RaeOneOfAKind.sideModal {
    background: white;
    padding:0px 40px;
    padding-bottom: 60px;
    z-index: 1002;
}

.RaeOneOfAKind.sideModal .itemModal-closeButton {
    margin-top: 40px;
}

.RaeOneOfAKind-content {
    width: 100%;
    margin-top: 30px;
}

.RaeOneOfAKind-content h1 {
    font-size: 30px;
    margin-bottom: 30px;
}

.RaeOneOfAKind-FotoAnneleen {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.RaeOneOfAKind-content ol {
    margin: 30px auto;
}

.RaeOneOfAKind-content li {
    font-weight: 800;
}

.RaeOneOfAKind-werkbank {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}