.sideModal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  background-color: white;
  box-shadow: -1px 0px 15px rgba(0, 0, 0, 0.01);
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 100;
}

.sideModal.shopItem {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 35%;
  background-color: white;
  box-shadow: -1px 0px 15px rgba(0, 0, 0, 0.01);
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 100;
}

.RaeCollection-itemContainer-item {
  width: 19%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  aspect-ratio: 1/1;
}

.RaeCollection-itemContainer-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.sideModal button.itemModal-closeButton {
  align-self: flex-end;
  border: none;
  background: none;
  font-size: 20px;
}

.ItemPage {
  height: 100vh;
}

.ItemPage-container {
  width: 100%;
  padding: 20px;
  position: relative;
  top: 10vh;
  background: rgb(250,225,216);
}

.ItemPage-container-itemCard {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.item-backButton {
  cursor: pointer;
}

.backlink {
  all: unset;
}

.backlink:hover {
  color: black;
  font-weight: 800;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 40px;
  width: 80%;
}

.item-content-images {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.item-content-images-otherImageGallery {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  width: 10%;
}

.item-content-images-otherImageGallery .item-image {
  width: 100%;
  margin-bottom: 10px;
}

.item-content-images-otherImageGallery .item-image.inactive {
  opacity: 0.5;
}

.item-content-images-mainImage {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-content-images-mainImage .item-image {
  width: 90%;
}

h1.item-content-title {
  font-size: 30px;
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
}

.item-content-orderForm {
  width: 100%;
  height: 100%;
}

.item-form {
  margin: 30px 0;
}

.item-content-orderForm-specs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 20px;
}

.item-content-orderForm-specs p {
  margin: 0px 0px;
  font-size: 14px;
  font-weight: 300;
}

.item-content-orderForm-specs p .bold {
  font-weight: bold;
}

.item-price {
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.itemPage-select-group {
  margin: 20px auto;
}

.itemPage-select:focus {
  box-shadow: none;
  border: 1px solid black;
}

.add-to-cart-btn {
  background-color: black;
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.add-to-cart-btn:hover {
  background-color: black;
  font-weight: 800;
}

.add-to-cart-btn:focus,
.add-to-cart-btn:active {
  box-shadow: none;
  background: black;
}

.add-to-cart-btn.back-to-collection-btn {
  background: rgb(100,100,100);
  margin-top: 0px;
  margin-left: 10px;
}

.RaeCollection-rescue-intro {
  text-align: center;
  margin: 20px 0;
  font-style: italic;
  background: rgba(250,225,215,0.5);
  border-radius: 5px;
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
}

/* New filter alignment styles */
.filters-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.regular-filters {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-item.rescue-plan {
  margin-left: auto;
}

@media (max-width: 900px) {
  .cartButton {
    color: white;
  }

  .sideModal {
    width: 100%;
    height: 100%;
  }

  .sideModal.shopItem {
    width: 100%;
    height: 100%;
  }

  .RaeCollection-itemContainer-item {
    width: 45%;
    display: flex;
  }

  .item-content {
    width: 90%;
  }

  .filters-container {
    flex-direction: column;
    gap: 10px;
  }
  
  .filter-item.rescue-plan {
    margin-left: 0;
    margin-top: 10px;
  }
}