
.JewelryDesignerForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.JewelryDesignerForm.active {
    width: 80%;
    margin-left: 18%;
}

.pageTopNavigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto;
    font-size: 14px;
    cursor: pointer;
}

.aboutThisPageQuestionMark{
    background: rgb(50,50,50);
    color: white;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    margin-right: 5%;
}

.pageTopNavigation a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: black;
    text-decoration: none;
}

.titleRow {
    width: 90%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.titleRow h1 {
    font-size: 2.5rem;
    font-weight: 800;
    color: rgba(0,0,0,0.8);
}

.contentRow {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 50px;
}
.imageGenerationSettings {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 30px;
}

.imageGenerationSettings .jewelry-designer-form {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #fff;
}

.imageGenerationSettings .jewelry-designer-form label {
    margin-bottom: 10px;
}

.imageGenerationSettings .jewelry-designer-form select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.imageGenerationSettings .jewelry-designer-form button.imageCreationButton {
    background-color: rgba(0,0,0,0.8);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.imageGenerationSettings .jewelry-designer-form button.imageCreationButton:disabled {
    background-color: rgba(0,0,0,0.3);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: not-allowed;
    margin-top: 20px;
}

.imageGenerationSettings .jewelry-designer-form button.imageCreationButton:hover {
    background-color: black;
}

.imageGenerationSettings .jewelry-designer-form button.imageCreationButton:disabled:hover {
    background-color: rgba(0,0,0,0.3);
}

.imageViewer {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgb(245,245,245);
    min-height: 60vh;
    padding: 30px;
    padding-bottom: 80px;
    border-radius: 20px;
    position: relative;
}

.imageViewer.two {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(245,245,245,0);
    min-height: 60vh;
    padding: 30px;
    padding-bottom: 80px;
    border-radius: 20px;
    position: relative;
}



.imageViewer .buttonGroup {
    display: flex;
    flex-direction: row;
}

.imageViewer .buttonGroup .toggleButton {
    background: none;
    color: black;
    border: 1px solid black;
    padding: 5px 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.imageViewer .buttonGroup .toggleButton.active {
    background: black;
    color: white;
}

.imageViewer h5 {
    font-size: 1.4rem;
    font-weight: 800;
    color: rgba(0,0,0,0.8);
    margin-bottom: 20px;
}

.imageViewer h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(50,50,50);
    position: relative;
    top: -50px;
    z-index: 200;
}

.pageContentContainer {
    width: 100%;
    position: relative;
}

.pageContentContainer h2 {
    font-size: 1.6rem;
}

.pinkUnderline{
    
    height: 7px;
    border-radius: 3px;
    background: rgb(237,190,174);
    position: absolute;
    left: 0px;
    top: -28px;
}

.pinkUnderline.jouwOntwerpen {
    width: 180px;
}

.pinkUnderline.andereAIOntwerpen {
    width: 240px;
}

.pinkUnderline.jouwSelecties {
    width: 145px;
}

.imageViewer .containerForGeneratedImages {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imageViewer .containerForGeneratedImages .imageCard {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
}

.imageViewer .containerForGeneratedImages .promptCard {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.promptCard button {
    background: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.promptCard .revisedPromptTextContainer {
    width: 100%;
    padding-bottom: 50px;
    position: relative;

}

.revisedPromptTextContainer .editPromptButton {
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: none;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 0px;
}

.imageCard .addToSelectionButton {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 100%;
    color: white;
    background: rgba(0,0,0,0.3);
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.pickSelectionMenu.active {
    position: absolute;
    bottom: 0px;
    height:100%;
    width: 100%;
    background: black;
    color: white;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.pickSelectionMenu.inactive {
    display: none;
}

.selectionPickerHeaderRow {
    display: flex;
    width: 100%;
    height: 50px;
    position: relative;
    align-items: center;
    justify-content: center;
}

.selectionPickerHeaderTitle {
    position:absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    font-weight: 800;
}



.selectionPickerHeaderRow .closeXButton {
    position: absolute;
    top: 20px;
    right: 25px;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    cursor: pointer;
}

.listOfAvailableSelections {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    width: 100%;
    height: 100%;
    color: white;
}

.SelectionOption {
    background: rgb(20,20,20);
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.commentArea {
    width: 100%;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    padding: 10px;
    position: relative;
    top: -14px;
    padding-bottom: 30px;
}

.commentArea p {
    color:rgb(50,50,50);
    padding: 10px;
    width: 90%;
}

.commentArea .editComment div {
    background: black;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    bottom: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.deleteImageButton {
    background: rgba(250,50,50,1);
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.noImageGeneratedText {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imageViewerShareFavorites {
    width: 80%;
    margin: 50px auto;
    text-align: center;
}

button.shareSelectionBucket {
    background: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    position: relative;
    top: -30px;
}

.imageViewerShareFavorites input {
    padding-left: 10px;
    border-radius: 5px;
}

.buttonRow.desktopOnly {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .optionSelector.mobileOnly {
    display: none;
}

.profile {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.profile-circle {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background: black;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 5px;
}

.profile-dropdown {
    position:absolute;
    display: none;
    z-index:0;
}

.profile-dropdown.active {
    position:absolute;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50px;
    z-index: 2;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.profile-dropdown .colorBorder {
    width: 100%;
    height: 50px;
    background:rgb(237,190,174);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.selectionBucketTileRow {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.selectionBucketTile {
    display: flex;
    width: 32%;
    height: 400px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px;
    background: white;
    color: black;
    border-radius: 10px;
    cursor: pointer;
}

.selectionBucketTile p {
    margin-left: 10px;
    font-size: 18px;
    margin-top: 0px;
    position: relative;
    top: -10px;
}

.selectionBucketTile .selectionBucketTile-imageContainer {
    width: 100%;
    height: 220px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 0fr));
    grid-auto-rows: 100px;
    grid-gap: 0px; /* Adjust as needed */
    overflow: hidden;

}

.selectionBucketTile .selectionBucketTile-imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Keeps the aspect ratio */
    object-position: center;
 }

 .selectionBucketTile-bucketTitleRow {
    width: 95%;
    margin: 0px auto;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

 .selectionBucketTile-bucketTitleRow-penIcon {
    font-size: 14px;
    cursor: pointer;
    margin-left: 30px;
    position: relative;
    bottom: 2px;
 }

 .selectionBucketTile-buttonRow {
    width: 95%;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
 }

 .selectionBucketTile-buttonRow button {
    background: black;
    color: white;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
 }

 .selectionBucketTile {
    border: 1px solid rgba(50,50,50,0.1);
    box-shadow: 1px 1px 2px rgba(50,50,50,0.05);
 }

 .selectionBucketTile:hover {
    border: 1px solid rgba(50,50,50,0.1);
    box-shadow: 1px 1px 5px rgba(50,50,50,0.1);
 }

 .selectionBucketDeepDive .containerForGeneratedImages {
    border: 1px solid rgba(50,50,50,0.1);
    box-shadow: 1px 1px 5px rgba(50,50,50,0.1);
    border-radius: 10px;
 }

 .titleWithEditOption-bigTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

 .selectionBucketDeepDive-headerRow-backButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
 }

 .titleWithEditOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 50%;
    position: relative;
 }

 .titleWithEditOption-title {
    font-size: 1.8rem;
    font-weight: 800;
    color: rgba(0,0,0,0.8);
    position: relative;
    top: 0px !important;
    width: 200px;
 }

 .titleWithEditOption-bigTitle {
    width: 200px;
 }

 .headerRow-selectionDeepDive {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    top: -30px;
 }

 .headerRow-selectionDeepDive button {
    background: black;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
 }

 


@media (max-width: 800px) {

    .contentRow {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 50px;
    }

    .imageGenerationSettings {
        width: 100%;
    }
    .imageViewer {
        width: 100%;
    }

    .buttonRow.desktopOnly {
        display: none;
    }

    .optionSelector.mobileOnly {
        display: flex;
    }

    .imageViewer {
        margin-bottom: 50px;
    }

    .selectionBucketTiles {
        width: 100%;
    }

    .selectionBucketTileRow {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .selectionBucketTile {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .selectionBucketTile p {
        margin-left: 10px;
    }

    .pageTopNavigation {
        height: 80px;
        position: fixed;
        top: 0px;
        background: white;
        z-index: 1000;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .imageGenerationSettings .jewelry-designer-form {
        margin-top: 20px;
    }

    .pageContentContainer h2 {
        font-size: 1.6rem;
        margin-top: 28px;
    }
    
    .pinkUnderline{
        
        height: 7px;
        border-radius: 3px;
        background: rgb(237,190,174);
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .aboutThisPageQuestionMark{
        background: rgb(50,50,50);
        color: white;
        display: none;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        margin-right: 10%;
        margin-top: 15px;
    }

}


