.PaymentSucceeded {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
}
.PaymentSucceeded .gemIcon {
    width: 100%;
    color: rgba(237,190,174, 1);
    font-size: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.PaymentSucceeded .successText {
    width: 100%;
    margin-bottom: 30px;
}