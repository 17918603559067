.RaeTermsAndConditions.sideModal {
    background: white;
    padding:0px 40px;
    padding-bottom: 60px;
    z-index: 1000;
}

.RaeTermsAndConditions.sideModal .itemModal-closeButton {
    margin-top: 40px;
}

.RaeTermsAndConditions-content {
    width: 100%;
    margin-top: 30px;
}

.RaeTermsAndConditions-content h1 {
    font-size: 20px;
}

.RaeTermsAndConditions-content ol {
    margin: 30px auto;
}

.RaeTermsAndConditions-content li {
    font-weight: 800;
}