.RaeCollection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    color: black;
}

.RaeCollection-title {
    width: 80%;
    text-align: left;
    font-weight: 300;
}

.RaeCollection-title-bold {
    font-weight: 800;
}

.RaeCollection-title-filterItemRow {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 20px;
}

.RaeCollection-title-filterItemRow-item-filters,
.RaeCollection-title-filterItemRow-material-filters {
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
}

.RaeCollection-title-filterItemRow-item-filters {
    justify-content: space-between; /* Changed to space-between */
    width: 100%; /* Changed to 100% to allow full width */
}

.RaeCollection-title-filterItemRow-item-filters .regular-filters {
    display: flex;
    align-items: center;
}

.RaeCollection-title-filterItemRow-item-filters div,
.RaeCollection-title-filterItemRow-material-filters div {
    padding: 10px;
    white-space: nowrap; /* Added to keep text on one line */
}

.RaeCollection-title-filterItemRow-item-filters .regular-filters div {
    padding-right: 10px;
    margin-right: 40px;
}

.RaeCollection-title-filterItemRow-item-filters .rescue-plan {
    margin-left: auto; /* Pushes the rescue plan to the right */
    margin-right: 0; /* Removes right margin for the rescue plan */
}

.RaeCollection-title-filterItemRow-material-filters {
    justify-content: flex-end;
    width: 30%;
}

.RaeCollection-title-filterItemRow-material-filters div {
    padding-left: 10px;
}

.RaeCollection-itemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    min-height: 100vh;
}

.active-filter {
    font-weight: 800;
}

.RaeCollection-title-filterItemRow-item-filters.mobile {
    display: none;
}

.RaeCollection-title-filterItemRow-material-filters.mobile {
    display: none;
}

/* smallerscreen */
@media (max-width: 900px) {
    .RaeCollection-itemContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        min-height: auto;
    }

    .RaeCollection-title-filterItemRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 80%;
    }

    .RaeCollection-title-filterItemRow-item-filters, 
    .RaeCollection-title-filterItemRow-material-filters {
        flex-direction: column;
        width: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .RaeCollection-title-filterItemRow-item-filters div, 
    .RaeCollection-title-filterItemRow-material-filters div {
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 0px;
        font-size: 18px;
    }

    .RaeCollection-title-filterItemRow-item-filters.desktop {
        display: none;
    }
    
    .RaeCollection-title-filterItemRow-item-filters.mobile {
        display: block;
        width: auto;
        border: none;
        display: flex;
        text-align: right !important;
        justify-content: flex-end;
        align-items: center;
        box-shadow: none;
        padding-top: 10px;
    }

    .RaeCollection-title-filterItemRow-item-filters.mobile:focus {
        display: block;
        width: 50%;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;
    }

    .price-card {
        font-size: 12px;
    }
}