.Footer {
  background:rgb(250,225,216);
  position: relative;
  top: 20px;
}

.Footer .site-footer {
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: black;
  width: 90%;
  margin: 0 auto;
  letter-spacing: 1px;
}
.Footer .site-footer hr {
  border-top-color: black;
  opacity: 0.5;
}
.Footer .site-footer hr.small {
  margin: 20px 0;
}
.Footer .site-footer h6 {
  color: black;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.Footer .site-footer a {
  color: black;
}
.Footer .site-footer a:hover {
  color: rgba(237,190,174, 1);
  text-decoration: none;
}
.Footer .footer-links {
  padding-left: 0;
  list-style: none;
}
.Footer .footer-links li {
  display: block;
}
.Footer .footer-links a {
  color: black;
}
.Footer .footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: rgba(237,190,174, 1);
  text-decoration: none;
}
.Footer .footer-links.inline li {
  display: inline-block;
}
.Footer .site-footer .social-icons {
  text-align: right;
}
.Footer .site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: black;
}
.Footer .copyright-text {
  margin: 0;
}

.Instagram {
  color: white !important;
  margin-bottom: 10px;
}

.FooterBottomLine {
  position: relative;
}

.FooterBottomLine p {
  position: absolute;
  bottom: 10px;
}

.Footer-aboutText {
 padding-right: 60px;
}

footer span.contact-text {
  margin: 20px;
}

.Footer h6 {
  margin-bottom: 10px !important;
}


/* The xl breakpoint */ 
@media (max-width: 1536px) {
  .Footer h6 {
    font-size: 14px !important;
  }

  .Footer p {
    font-size: 12px !important;
  }

  .Footer li {
    font-size: 12px !important;
  }
}

@media (max-width: 900px) {
  .Footer .site-footer {
    padding-bottom: 0;
  }
  .Footer .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.Footer .social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.Footer .social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.Footer .social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: black;
  font-weight: 700;
  font-size: 13px;
}
.Footer .social-icons a {
  background-color: black;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.Footer .social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: black;
  background-color: rgba(237,190,174, 1);
}
.Footer .social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.Footer .social-icons a.Instagram:hover {
  background-color: rgb(237,190,174);
}
@media (max-width: 767px) {
  .Footer .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
